import React from 'react'
import styles from '@/styles/Footer.module.css'
import Image from 'next/image'
// import ReviewButton from './ReviewButton'
import { useTranslation } from 'react-i18next'

const Extras = () => {
  const { t, i18n } = useTranslation()

  return (
    <div className={styles.card}>
      <fieldset className={styles.fieldset}>
        <legend>{t('Extra toppings on pizza')}</legend>
        <ul className={styles.fieldsetList}>
          <li className={styles.legendListWrapper}>
            <div className={styles.legenList}>
              {t('Cheese, Mushrooms, Pineapple, Tomatoes')}
              {t(' Olives, Eggs, Gorgonzola')}
            </div>
            <span className={styles.span}>15kr</span>
          </li>
          <li>
            {t('Salami, Mussels, Double dough on pizza')}
            <span className={styles.span}>20kr</span>
          </li>
          <li>
            {t('Bacon, minced meat, ham')}
            <span className={styles.span}> 20kr</span>
          </li>
          <li>
            {t('Prawns, Kebab, Parma ham, Beef tenderloin(Oxfilé)')}
            <span className={styles.span}> 30kr</span>
          </li>
          <li>
            {t('Fries on the pizza')}
            <span className={styles.span}> 30kr</span>
          </li>
          <li>
            {t('Fries in a roll')}
            <span className={styles.span}> 15kr</span>
          </li>
        </ul>
      </fieldset>
      <section className={styles.swishSection}>
        <figure className={styles.swishContianer}>
          <Image
            className={styles.logoSwish}
            src='/swish.svg'
            alt='swish'
            width={50}
            height={50}
            loading='lazy'
          />
        </figure>
        <figcaption className={styles.swishDesc}>
          {t('It works well with cards or Swish')}
        </figcaption>
        <address className={styles.text}>
          <p className={styles.tel}>
            Tel:
            <a title='Ring oss' href='tel:08934910'>
              08-93 49 10
            </a>
          </p>
          <p>{t('Visit us: sparres väg 1 Bro')}</p>
          <address className={styles.website}>www.labambi.se</address>
        </address>
      </section>
      {/* <ReviewButton /> */}
    </div>
  )
}

export default Extras
